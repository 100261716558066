<template>
  <div class="infomation-wrap">
      <div class="container clearfix">
          <div class="notice-left">
            <div class="notice-list">
                <template v-for="(linkChunk) in linkData" >
                <div class="notice-link"
                        v-for="(link) in linkChunk.links"
                        :class="{active: link.href.indexOf(fullPath) > -1}"
                        :key="link.title"
                    >
                        <a
                        :href="link.href"
                        >
                        {{ link.title }}</a>
                    </div>
                </template>
            </div>
          </div>
          <div class="notice-right">
              <NoticeContent :doc="doc">
              </NoticeContent>
          </div>
      </div>
  </div>
</template>

<script>
import linkData from '@/common/link-data'

import NoticeContent from './noticeContent.vue'

export default {
  components: {
    NoticeContent
  },
  data () {
    return {
      linkData
    }
  },
  computed: {
    doc () {
      return this.$route.query.doc
    },
    fullPath () {
      return this.$route.fullPath
    }
  },
  created () {
    console.log(this.$route)
  },
  methods: {

  }
}
</script>

<style lang="less">
.infomation-wrap {
    padding: 20px 0;
    min-height: 100vh;

    .container {
        width:1200px;
        margin: 0 auto;
        background: #d1e6ff;
    }

    a {
        color: #333
    }

    .notice-left {
        width: 250px;
        float: left
    }
    .notice-right {
        background: #fff;
        margin-left: 250px;
    }

    .notice-list {
        margin-top: 30px;
    }
    .notice-link {
        height: 40px;
        line-height: 40px;
        padding: 0 30px;
        border-left: 5px solid transparent;

        &.active {
            background: #fff;
            border-left: 5px solid @joy-orange-color;
        }
    }
}

</style>
