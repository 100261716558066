<template>
    <div class="notice-content">
        <div class="notice-content">
            <component v-bind:is="doc"></component>
        </div>
    </div>
</template>

<script>
export default {
  name: 'notice-content',
  components: {
    introduce: () => import('./content/introduce'),
    transport: () => import('./content/transport'),
    online: () => import('./content/online'),
    baggage: () => import('./content/baggage'),
    refund: () => import('./content/refund'),
    contact: () => import('./content/contact')
  },
  props: {
    doc: String
  },
  data () {
    return {
      comName: ''
    }
  }
}
</script>

<style>
.doc-outter {
    padding: 20px;
}
.doc-title {
    border-bottom: 2px solid #dadada;
    margin-bottom: 30px;
}
.doc-content {}

.t-c {
    text-align: center;
}
</style>
